import { DynamicReportType, PatrolTourType, ReportTemplateEnum } from "src/models/ReportModel";
import { ExceptionType } from "src/models/reportTemplates";

import { getFieldValueByFieldName } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { extractExceptionQuestionsFromReport } from "../../../helpers/reportTemplates/extractExceptionQuestionsFromReport";
import { ExceptionReports } from "./TourExceptionsOverview.types";

export const parseSharedReportToExceptions = (report: PatrolTourType): ExceptionReports => {
    return {
        tourMultiExceptions: (report.exceptions ?? []).map(
            ({ exceptions }: { exceptions: { question: string; answer: string }[]; id: string; template: string }) => ({
                exceptionQuestions: exceptions.map(({ question, answer }) => ({
                    question,
                    answer,
                })),
            }),
        ),
    };
};

export const parseRelatedReportsToExceptions = (report: PatrolTourType, translationsMap: Map<string, string>): ExceptionReports => {
    const exceptionReports: ExceptionReports = { tourMultiExceptions: [], tourExceptions: [] };

    report.relatedReports?.map((relatedReport) => {
        if (relatedReport.template === ReportTemplateEnum.tourMultiException) {
            exceptionReports.tourMultiExceptions.push({
                exceptionQuestions: getOverviewedMultiExceptionQuestions(relatedReport, translationsMap),
                report: relatedReport,
            });
        }

        if (relatedReport.template === ReportTemplateEnum.tourException) {
            const exceptionQuestion = getFieldValueByFieldName("exceptionQuestion", relatedReport);
            const exceptionAnswer = getFieldValueByFieldName("exceptionAnswer", relatedReport);

            if (exceptionQuestion) {
                exceptionReports.tourExceptions.push({
                    report: relatedReport,
                    question: translationsMap[exceptionQuestion] ?? exceptionQuestion,
                    answer: translationsMap[exceptionAnswer] ?? exceptionAnswer,
                });
            }
        }
    });

    return exceptionReports;
};

const getOverviewedMultiExceptionQuestions = (report: DynamicReportType, translationsMap: Map<string, string>) => {
    const excludedExceptionTypes = [ExceptionType.Image, ExceptionType.HighQualityImage];

    return extractExceptionQuestionsFromReport(report)
        .filter(({ exceptionType }) => !excludedExceptionTypes.includes(exceptionType))
        .map(({ question, ...exception }) => ({
            ...exception,
            question: translationsMap.get(question) ?? question,
        }));
};
