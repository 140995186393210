import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../../data/featureFlags";
import FeatureFlagsActions from "../../store/featureFlags/FeatureFlagsActions";
import FeatureFlagsSelectors from "../../store/featureFlags/FeatureFlagsSelectors";
import { RequestStatus } from "../../store/RequestStatus";
import { Loading } from "../shared/Loading";

// useBooleanFlagValue tends to overregister event listeners when reused in hooks
// in such cases, feature flag can be stored in store and accessed with selector in hooks
export const FeatureFlagResolver = ({ children }: { children?: ReactNode }) => {
    const dispatch = useDispatch();
    const isAssignmentReportTypeEnabled = useBooleanFlagValue(featureFlags.assignmentReports, false);
    const isKpiSummaryEnabled = useBooleanFlagValue(featureFlags.kpiSummary, false);
    const isKpiDatepickerEnabled = useBooleanFlagValue(featureFlags.kpiDatepicker, false);
    const isSitePageTabsBarchartEnabled = useBooleanFlagValue(featureFlags.sitePageTabsBarchart, false);
    const isPersonalizeKpiSummaryEnabled = useBooleanFlagValue(featureFlags.personalizeKpiSummary, false);
    const isViewScheduleEnabled = useBooleanFlagValue(featureFlags.viewSchedule, false);
    const mobileGuardingTrackingEnabled = useBooleanFlagValue(featureFlags.mobileGuardingTracking, false);
    const featureFlagsStatus = useSelector(FeatureFlagsSelectors.selectFeatureFlagsStatus);
    const isTrueDeviationsEnabled = useBooleanFlagValue(featureFlags.trueDeviations, false);
    const isClientSeverityLevelEnabled = useBooleanFlagValue(featureFlags.clientSeverityLevel, false);
    const isClientSeverityLevelEditModeEnabled = useBooleanFlagValue(featureFlags.clientSeverityLevelEditMode, false);
    const isInsightsReportSearchEnabled = useBooleanFlagValue(featureFlags.insightsReportSearch, false);
    const isDemoEnabled = useBooleanFlagValue(featureFlags.inMemoryDemoClient, false) || process.env.REACT_APP_INMEMORY_DEMO_ENABLED === "true";

    useEffect(() => {
        dispatch(
            FeatureFlagsActions.setFeatureFlags({
                [featureFlags.assignmentReports]: isAssignmentReportTypeEnabled,
                [featureFlags.kpiSummary]: isKpiSummaryEnabled,
                [featureFlags.inMemoryDemoClient]: isDemoEnabled,
                [featureFlags.sitePageTabsBarchart]: isSitePageTabsBarchartEnabled,
                [featureFlags.personalizeKpiSummary]: isPersonalizeKpiSummaryEnabled,
                [featureFlags.mobileGuardingTracking]: mobileGuardingTrackingEnabled,
                [featureFlags.trueDeviations]: isTrueDeviationsEnabled,
                [featureFlags.viewSchedule]: isViewScheduleEnabled,
                [featureFlags.clientSeverityLevel]: isClientSeverityLevelEnabled,
                [featureFlags.clientSeverityLevelEditMode]: isClientSeverityLevelEditModeEnabled,
                [featureFlags.kpiDatepicker]: isKpiDatepickerEnabled,
                [featureFlags.insightsReportSearch]: isInsightsReportSearchEnabled,
            }),
        );
    }, [
        dispatch,
        isKpiSummaryEnabled,
        isAssignmentReportTypeEnabled,
        isDemoEnabled,
        isSitePageTabsBarchartEnabled,
        isPersonalizeKpiSummaryEnabled,
        mobileGuardingTrackingEnabled,
        isTrueDeviationsEnabled,
        isViewScheduleEnabled,
        isClientSeverityLevelEnabled,
        isClientSeverityLevelEditModeEnabled,
        isKpiDatepickerEnabled,
        isInsightsReportSearchEnabled,
    ]);

    if (featureFlagsStatus !== RequestStatus.success) {
        return <Loading size="L" />;
    }

    return children;
};
