import { difference, sum, union } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getLettersFromNumber } from "src/helpers/graphs";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { UserPreference } from "src/models/UserModel";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { useAuthorizedLocations } from "src/store/locations/LocationsHooks";
import { useSetUserPreference, useUserPreference } from "src/store/user/UserHooks";

import { SitePageTabLabel } from "../../shared/types";
import type { GraphData } from "../shared/components/Graph/Graph.types";
import { SUMMARY_EVENT_CATEGORIES } from "../shared/constants";
import { useSummaryPeriod } from "../shared/hooks";
import { useDeviationsSiteSummary } from "../shared/hooks/useDeviationsSiteSummary";
import type { WidgetViewMode } from "../Summary.types";
import { CHART_BAR_COUNT } from "./SiteEventsWidget.constants";
import { filterSiteEventsCountData } from "./SiteEventsWidget.helpers";
import { getSiteEventsCountQuery, parseSiteEventsResult } from "./SiteEventsWidget.queries";
import { SiteEventsData, SiteEventsDataItem, SiteEventsGraphData } from "./SiteEventsWidget.types";

export const useSiteEventsWidget = () => {
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const setUserPreference = useSetUserPreference();
    const [userPreference] = useUserPreference(UserPreference.SiteEventsSummaryCategories);
    const userCategories = useMemo(() => userPreference?.split(",") as CategoryLevel1[] | undefined, [userPreference]);
    const [activeCategories, setActiveCategories] = useState<CategoryLevel1[]>(userCategories ?? []);
    const { currentPeriod } = useSummaryPeriod();
    const { siteEventsCountData, isLoading } = useCombinedSitesEventsData(currentPeriod.start, currentPeriod.end);
    const siteEventsCountDataFiltered = filterSiteEventsCountData(siteEventsCountData, activeCategories);
    const siteEventsGraphData = useSiteEventsGraphData(siteEventsCountDataFiltered, CHART_BAR_COUNT, currentPeriod.start, currentPeriod.end);
    const isSwitcherVisible = siteEventsCountDataFiltered?.length > CHART_BAR_COUNT;
    const isCategoriesDropdownVisible = siteEventsCountData?.length > 0;
    const graphData = siteEventsGraphData[viewMode];

    useEffect(() => {
        if (!isSwitcherVisible) {
            setViewMode("most");
        }
    }, [isSwitcherVisible]);

    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    const updateActiveCategories = useCallback(
        (newCategories: CategoryLevel1[]) => {
            setActiveCategories(newCategories);
            const changedSinceSaving = difference(userCategories ?? [], newCategories).length || difference(newCategories, userCategories ?? []).length;

            if (changedSinceSaving) {
                setUserPreference(UserPreference.SiteEventsSummaryCategories, newCategories.join(","));
            }
        },
        [setActiveCategories, setUserPreference, userCategories],
    );

    return {
        viewMode,
        graphData: graphData,
        isSwitcherVisible,
        isCategoriesDropdownVisible,
        initCategories: userCategories,
        isLoading,
        isEmpty: !isLoading && !siteEventsCountDataFiltered?.length,
        setViewMode: handleSetViewMode,
        updateActiveCategories,
    };
};

const useSiteEventsCountQuery = (siteIds: string[], startDate: Date, endDate: Date) => {
    const queryParams = { siteIds, startDate, endDate };
    const { isLoading, queryResult } = useSqlQuery(getSiteEventsCountQuery, parseSiteEventsResult, queryParams);

    return { siteEventsCountData: queryResult, isLoading };
};

const useCombinedSitesEventsData = (
    startDate: Date,
    endDate: Date,
): {
    isLoading: boolean;
    siteEventsCountData: SiteEventsData;
} => {
    const { siteIds } = useFilteredSites();
    const { isLoading: isLoadingDeviations, sitesDeviationsCountMap } = useDeviationsSiteSummary(siteIds, startDate, endDate, SUMMARY_EVENT_CATEGORIES);
    const { isLoading: isLoadingEvents, siteEventsCountData } = useSiteEventsCountQuery(siteIds, startDate, endDate);
    const siteEventsData: SiteEventsData = [];

    if (siteEventsCountData && sitesDeviationsCountMap) {
        const allLocationIds = union(Object.keys(siteEventsCountData), Object.keys(sitesDeviationsCountMap));

        allLocationIds.forEach((locationId) => {
            const siteCategories = union(Object.keys(siteEventsCountData[locationId] ?? {}), Object.keys(sitesDeviationsCountMap[locationId] ?? {})).filter(
                (category) => ![CategoryLevel1Keys.internalSecuritas, "null"].includes(category),
            );
            const categoriesCounts: SiteEventsDataItem["categories"] = {};

            siteCategories.forEach((category) => {
                categoriesCounts[category] = (siteEventsCountData?.[locationId]?.[category] ?? 0) + (sitesDeviationsCountMap?.[locationId]?.[category] ?? 0);
            });

            siteEventsData.push({
                locationId,
                categories: categoriesCounts,
                totalCount: sum(Object.values(categoriesCounts)),
            });
        });
    }

    return {
        isLoading: isLoadingEvents || isLoadingDeviations,
        siteEventsCountData: siteEventsData,
    };
};

const useSiteEventsGraphData = (siteEventsCountData: SiteEventsData | undefined, resultCount: number, startDate: Date, endDate: Date): SiteEventsGraphData => {
    const { siteObjects } = useAuthorizedLocations();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const siteEventsGraphData = useMemo(() => {
        const getSiteName = (locationId: string) => {
            const site = siteObjects.find((x) => x.id === locationId);
            return site?.displayName || "";
        };

        const handleItemClick = (locationId: string) => {
            navigate(`/insights/site/${locationId}`, {
                state: {
                    startDate,
                    endDate,
                    originActiveTab: SitePageTabLabel.Events,
                },
            });
        };

        const getGraphData = (data: SiteEventsData): GraphData => {
            return data.map((item, index) => {
                const siteName = getSiteName(item.locationId);
                const name = getLettersFromNumber(index);

                return {
                    key: name,
                    name: name,
                    value: item.totalCount,
                    label: siteName,
                    secondLabel: `${item.totalCount}\u00A0${item.totalCount === 1 ? t("insights.overview.event") : t("insights.overview.event_plural")}`,
                    tooltip: [siteName],
                    onItemClick: () => handleItemClick(item.locationId),
                };
            });
        };

        if (!siteEventsCountData?.length) {
            return {
                most: { data: [] },
                least: { data: [] },
            };
        }

        const mostData = getGraphData(siteEventsCountData.slice(0, resultCount));
        const leastData = getGraphData(siteEventsCountData.slice(-resultCount).reverse());

        return {
            most: {
                data: mostData,
            },
            least: {
                data: leastData,
            },
        };
    }, [siteEventsCountData, resultCount, siteObjects, navigate, startDate, endDate, t]);

    return siteEventsGraphData;
};
