import { ActivityLogFields } from "../../models/reportTemplates/ActivityLog";
import { AlarmResponseFields } from "../../models/reportTemplates/AlarmResponse";
import { CalloutFields } from "../../models/reportTemplates/Callout";
import { IncidentFields } from "../../models/reportTemplates/Incident";
import { MaintenanceFields } from "../../models/reportTemplates/Maintenance";
import { SafetyInspectionFields } from "../../models/reportTemplates/SafetyInspection";
import { SiteInspectionFields } from "../../models/reportTemplates/SiteInspection";
import { TaskReportFields } from "../../models/reportTemplates/Task";
import { AmazonGeneralFields } from "./AmazonGeneral";
import { AmazonZone6Fields } from "./AmazonZone6";
import { AssignmentFields } from "./Assignment";
import { TourExceptionFields } from "./TourException";
import { TourMultiExceptionFields } from "./TourMultiException";
import { VehicleInspectionFields } from "./VehicleInspection";
import { IsoStringDate } from "../../helpers/date";

export {
    ActivityLogFields,
    AlarmResponseFields,
    AmazonGeneralFields,
    AmazonZone6Fields,
    CalloutFields,
    IncidentFields,
    AssignmentFields,
    MaintenanceFields,
    SafetyInspectionFields,
    SiteInspectionFields,
    TaskReportFields,
    TourMultiExceptionFields,
    TourExceptionFields,
    VehicleInspectionFields,
};

export enum ExceptionType {
    Signature = "exception_signature",
    Image = "exception_picture",
    HighQualityImage = "exception_high_quality_picture",
    Yes = "exception_yes",
    No = "exception_no",
    TextField = "...",
    Range = "....",
}

export type ExceptionQuestionData = {
    answer?: string;
    question: string;
    questionIndex?: number;
    isException?: boolean;
    scannedOn?: IsoStringDate;
    exceptionType?: ExceptionType;
};
