import { Box, Breakpoints, Stack, Tab, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { InsightsDateFilter } from "src/components/Insights/Filter/InsightsDateFilter";
import { ScheduleDateFilter } from "src/components/Schedule/ScheduleDateFilter";
import { PageWrapper } from "src/components/shared/PageWrapper";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { ScheduleTableSection } from "src/pages/Schedule/ScheduleTableSection";

import { useTours } from "../../../store/insights/Tours/ToursHooks";
import { InsightsWrapper } from "../Common.styled";
import useSelectDateRangeFromLocation from "../shared/hooks/useSelectDateRangeFromLocation";
import { SitePageTabLabel } from "../shared/types";
import { IncidentsTab } from "./components/tabs/IncidentsTab";
import { TasksTab } from "./components/tabs/TasksTab";
import { StackStyled, TabsStyled } from "./InsightsSitePage.styled";
import { SelectedCategories } from "./InsightsSitePage.types";
import { InsightsSitePageHeader } from "./InsightsSitePageHeader";

export const InsightsSitePageV2 = () => {
    const { t } = useTranslation();
    const { siteId } = useParams<{ siteId?: string }>();
    const { state } = useLocation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    useTours();
    useSelectDateRangeFromLocation();
    const isViewScheduleVisible = useFeatureFlag(featureFlags.viewSchedule);
    const [selectedCategories, setSelectedCategories] = useState<SelectedCategories>({ level1: null, level2: null });
    const levelChangeHandler = (selectedCategories: SelectedCategories) => {
        setSelectedCategories(selectedCategories);
    };

    const TabsList: Array<{
        labelKey: string;
        component: React.ComponentType<any>;
        props?: any;
    }> = [
        isViewScheduleVisible
            ? {
                  labelKey: SitePageTabLabel.ViewSchedule,
                  component: ScheduleTableSection,
                  props: {
                      locationId: siteId,
                  },
              }
            : null,
        {
            labelKey: SitePageTabLabel.Events,
            component: IncidentsTab,
            props: {
                selectedCategories: selectedCategories,
                levelChangeHandler: levelChangeHandler,
            },
        },
        {
            labelKey: SitePageTabLabel.Tasks,
            component: TasksTab,
        },
    ].filter(Boolean);
    const [activeTab, setActiveTab] = useState(TabsList.findIndex((tab) => tab.labelKey === state?.originActiveTab) || 0);

    const isLevel1OnMobile = selectedCategories.level1 && isMobile;

    const levelResetHandler = (index: number) => {
        if (index === eventsTabIndex && activeTab === eventsTabIndex && selectedCategories.level1) {
            setSelectedCategories({ level1: null, level2: null });
        }
    };

    const eventsTabIndex = TabsList.findIndex((tab) => tab.labelKey === SitePageTabLabel.Events);
    const viewScheduleTabIndex = TabsList.findIndex((tab) => tab.labelKey === SitePageTabLabel.ViewSchedule);
    const displayScheduleDatePicker = useMemo(
        () => isViewScheduleVisible && activeTab === viewScheduleTabIndex,
        [isViewScheduleVisible, activeTab, viewScheduleTabIndex],
    );

    return (
        <PageWrapper direction="column" pt={isMobile ? "XXS" : "0"}>
            <InsightsWrapper pb="S" direction="column">
                <Box ph={isMobile ? "S" : "L"}>
                    <InsightsSitePageHeader
                        selectedCategories={selectedCategories}
                        activeTab={activeTab}
                        levelChangeHandler={levelChangeHandler}
                        siteId={siteId}
                        InsightsDateFilter={displayScheduleDatePicker ? ScheduleDateFilter : InsightsDateFilter}
                        eventsTabIndex={eventsTabIndex}
                    />
                </Box>
                <Stack gap="S" direction="column">
                    <TabsStyled
                        data-testid="insights-tabs"
                        useScrollIntoView={false}
                        value={activeTab}
                        onChange={setActiveTab}
                        isNavigationVisible={!isLevel1OnMobile}
                        hasBottomSeparator={!isLevel1OnMobile}
                        onClick={levelResetHandler}
                        scrollBehavior="instant"
                    >
                        {TabsList.map((tab, index) => {
                            const Page = tab.component;

                            return (
                                <Tab key={tab.labelKey} label={t(tab.labelKey)}>
                                    <StackStyled direction="column" flex={1} gap="S">
                                        {activeTab === index && <Page {...tab.props} />}
                                    </StackStyled>
                                </Tab>
                            );
                        })}
                    </TabsStyled>
                </Stack>
            </InsightsWrapper>
        </PageWrapper>
    );
};
