import { createSlice } from "@reduxjs/toolkit";
import { mapToUser } from "src/models/UserModel";

import { userApi } from "../user/userApi";
import { DEFAULT_USERS_STATE } from "./constants";

export const usersSlice = createSlice({
    name: "users",
    initialState: DEFAULT_USERS_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.getUsersByIds.matchFulfilled, (state, action) => {
            action.payload.forEach((user) => {
                state.byIds.users[user.id] = mapToUser(user);
            });
        });
    },
});
