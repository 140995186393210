import { ButtonText, Icon, Stack } from "@secuis/ccp-react-components";
import { ButtonTextProps } from "@secuis/ccp-react-components/dist/types/components/button/buttonText/ButtonText";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { ButtonTextStyled } from "./ViewExceptionButton.styles";

export const ViewExceptionButton = ({ disabled, onClick }: Pick<ButtonTextProps, "disabled" | "onClick">) => {
    const { t } = useTranslation();
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    return isTrueDeviationsEnabled ? (
        <ButtonTextStyled disabled={disabled} onClick={onClick}>
            <Stack alignItems="center" gap="XS">
                <Icon variant="Deviations" /> {t("reportdetails.patrolTour.viewException")}
            </Stack>
        </ButtonTextStyled>
    ) : (
        <ButtonText color="accent" disabled={disabled} onClick={onClick}>
            {t("reportdetails.patrolTour.viewException")}
        </ButtonText>
    );
};
