import { View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { PatrolTourType } from "../../../models/ReportModel";
import { styles } from "../pdfStyles";
import { DynamicTemplateSectionPdf } from "../shared/PdfDynamicTemplateSection";
import { TextSummaryFieldPdf } from "../shared/PdfSummarySections";
import { useTourExceptionsOverviewPdf } from "./TourExceptionsOverviewPdf.hooks";

interface PropsType {
    report: PatrolTourType;
    sharedReportId: string;
}

export const TourExceptionsOverviewPdf = ({ report, sharedReportId }: PropsType) => {
    const { t } = useTranslation();
    const { tourExceptions } = useTourExceptionsOverviewPdf({ report, sharedReportId });

    return (
        tourExceptions.length && (
            <DynamicTemplateSectionPdf label={t("reportdetails.report.tourExceptions")} renderLineBreak={false} marginTop="S">
                {tourExceptions.map(({ question, answer }, index) => (
                    <>
                        <TextSummaryFieldPdf key={`${question}_${index}`} fieldName={question} fieldValue={answer} />
                        <View
                            style={
                                index === tourExceptions.length - 1
                                    ? {
                                          ...styles.lineBreak,
                                          marginBottom: 0,
                                      }
                                    : styles.lineBreak
                            }
                        />
                    </>
                ))}
            </DynamicTemplateSectionPdf>
        )
    );
};
