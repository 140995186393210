import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapInfo = ({ user: { info, invalidated } }: IState) => ({ info, invalidated });
const getUserInfo = createSelector([mapInfo], (infoState) => infoState);
const getUserId = createSelector([mapInfo], (infoState) => infoState?.info?.id);

const selectors = {
    getUserInfo,
    getUserId,
};
export default selectors;
