import { QaProps, StyleProps } from "src/models";

import { StatsList, Widget } from "../shared/components";
import { useReportsCountWidget } from "./ReportsCountWidget.hooks";

type Props = QaProps & StyleProps;

export const ReportsCountWidget = (props: Props) => {
    const { countStats, isLoading } = useReportsCountWidget();

    return (
        <Widget {...props} data-testid="reports-count-widget">
            <StatsList stats={countStats} isLoading={isLoading} />
        </Widget>
    );
};
