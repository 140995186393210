import numeral from "numeral";

export const compactNumber = (nubmer: number) => {
    const formatted = numeral(nubmer).format("0.[0]a");

    return formatted.replace(/\.0$/, "").toUpperCase();
};

export const parseStringToNumber = (value: string | number) => {
    if (typeof value === "string") {
        return parseInt(value);
    }

    return value;
};
