import { Breakpoints, Stack } from "@secuis/ccp-react-components";
import { RefObject } from "react";
import { useElementHasMaxWidth } from "src/hooks/useElementHasMaxWidth";

import { useDraggableGrid } from "./DraggableGrid.hooks";
import { DraggableGridProps } from "./DraggableGrid.types";
import { DraggableWidgetWrapper } from "./DraggableWidgetWrapper";
import { RemovedWidgetsGrid } from "./RemovedWidgetsGrid";
import { ResponsiveGridStyled } from "./shared/ResponsiveGridStyled.styles";

export const DraggableGrid = (props: DraggableGridProps) => {
    const {
        isEditable,
        layouts,
        currentLayout,
        removedLayout,
        draggedId,
        breakpoints,
        columns,
        widgetsMap,
        rowHeight,
        addedWidgets,
        handleLayoutChange,
        handleDragStop,
        handleDragStart,
        handleRemoveWidget,
        handleAddWidget,
    } = useDraggableGrid(props);
    const { hasMaxWidth: isMobileOrTablet, elementRef } = useElementHasMaxWidth(Breakpoints.S);

    return (
        <Stack direction="column" gap="S" ref={elementRef as RefObject<HTMLDivElement>}>
            <ResponsiveGridStyled
                layouts={layouts}
                onLayoutChange={handleLayoutChange}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                isDraggable={isEditable && !isMobileOrTablet} // NOTE: We disable dragging on Breakpoints.S CONTAINER width.
                rowHeight={rowHeight}
                breakpoints={breakpoints}
                cols={columns}
                isEmpty={!currentLayout.length}
            >
                {currentLayout?.map(({ i }) => {
                    // display static variant for added widgets
                    const Content = addedWidgets.includes(i) ? widgetsMap[i]?.Placeholder : widgetsMap[i]?.Content;

                    return (
                        Content && (
                            <DraggableWidgetWrapper widgetId={i} key={i} onRemove={handleRemoveWidget} isDragged={draggedId === i} editMode={isEditable}>
                                <Content />
                            </DraggableWidgetWrapper>
                        )
                    );
                })}
            </ResponsiveGridStyled>
            {isEditable ? (
                <RemovedWidgetsGrid
                    layout={removedLayout}
                    handleAddWidget={handleAddWidget}
                    widgetsMap={widgetsMap}
                    rowHeight={rowHeight}
                    breakpoints={breakpoints}
                    columns={columns}
                    editMode={isEditable}
                />
            ) : null}
        </Stack>
    );
};
