import { Image, Text, View } from "@react-pdf/renderer";
import { Palette, Spacing } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { PatrolTourType } from "../../models/ReportModel";
import { GetCheckpointsSettingsData } from "../Reports/PatrolSummaryComponent/PatrolSummaryHelper";
import { styles } from "./pdfStyles";

type Props = {
    report: PatrolTourType;
};

const DotPdf = ({ color, marginLeft = null }) => <View style={{ ...styles.dot, backgroundColor: color, marginLeft: marginLeft }} />;

export const PatrolSummaryPdf = ({ report }: Props) => {
    const { t } = useTranslation();
    const checkpoints = GetCheckpointsSettingsData(report);

    return (
        <View style={styles.inlineFullWidth}>
            <View>
                <Text style={styles.boldText}>{report.tourName}</Text>
                <View style={styles.flexRow}>
                    <Text style={{ ...styles.completionPercentage, color: checkpoints.percentage < 91 ? Palette.Red500 : Palette.Green500 }}>
                        {checkpoints.percentage}%
                    </Text>
                    {report.pdfPieChart && (
                        <View>
                            <Image style={styles.pieChart} src={report.pdfPieChart} />
                        </View>
                    )}
                </View>
            </View>
            <View style={{ width: 200 }}>
                <View style={styles.inlineFullWidth}>
                    <Text>{t(checkpoints.total.name)}</Text>
                    <Text>{checkpoints.total.value}</Text>
                </View>
                <View style={styles.lineBreak} />
                <View style={styles.inlineFullWidth}>
                    <View style={styles.flexRow}>
                        <DotPdf color={checkpoints.scanned.colorPdf} />
                        <Text>{t(checkpoints.scanned.name)}</Text>
                    </View>
                    <Text>{checkpoints.scanned.value}</Text>
                </View>
                <View style={styles.lineBreak} />
                {checkpoints.exceptions.value > 0 && (
                    <>
                        <View style={styles.inlineFullWidth}>
                            <View style={styles.flexRow}>
                                <DotPdf color={checkpoints.exceptions.colorPdf} marginLeft={Spacing.S} />
                                <Text>{t(checkpoints.exceptions.name)}</Text>
                            </View>
                            <Text>{checkpoints.exceptions.value}</Text>
                        </View>
                        <View style={styles.lineBreak} />
                    </>
                )}

                <View style={styles.inlineFullWidth}>
                    <View style={styles.flexRow}>
                        <DotPdf color={checkpoints.missed.colorPdf} />
                        <Text>{t(checkpoints.missed.name)}</Text>
                    </View>
                    <Text>{checkpoints.missed.value}</Text>
                </View>
            </View>
        </View>
    );
};
