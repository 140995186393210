import { format } from "date-fns";
import { subMonths } from "src/helpers/date";
import { DeviationsAPIData } from "src/pages/Insights/Insights.types";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

export const parseDeviationsResponse = (deviations: DeviationsAPIData, categories: string[], monthsBack: number): ITrendingEvent[] => {
    if (!deviations.length) {
        return [];
    }

    const deviationsMap = deviations.reduce(
        (map, { yearMonth, category, count }) => {
            if (!map[yearMonth]) {
                map[yearMonth] = {};
            }
            map[yearMonth][category] = (map[yearMonth][category] || 0) + count;
            return map;
        },
        {} as { [yearMonth: string]: { [category: string]: number } },
    );

    const result: ITrendingEvent[] = [];

    for (let i = monthsBack; i >= 0; i--) {
        const date = subMonths(new Date(), i);
        const yearMonth = format(date, "yyyy-MM");
        const month = date.getMonth() + 1;
        const monthData = { month };

        categories.forEach((category) => {
            monthData[category] = deviationsMap[yearMonth]?.[category] || 0;
        });

        result.push(monthData);
    }

    return result;
};
