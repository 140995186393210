import { LoadingIndicator } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DynamicTemplateSection } from "src/components/shared/Section/DynamicTemplateSection";
import { PatrolTourType } from "src/models/ReportModel";
import { ISiteObject } from "src/models/SiteObjectModel";

import { ReportDisplayModal } from "./ReportDisplayModal";
import { TourExceptionField } from "./TourExceptionField";
import { useTourExceptionsOverview } from "./TourExceptionsOverview.hooks";
import { TourMultiExceptionField } from "./TourMultiExceptionField";

interface Props {
    report: PatrolTourType;
    siteObject: ISiteObject;
    sharedReportId: string;
    shouldAutoTranslate?: boolean;
}

export const TourExceptionsOverview = ({ report, siteObject, sharedReportId, shouldAutoTranslate }: Props) => {
    const { t } = useTranslation();
    const [leftOffset, setLeftOffset] = useState<number>(0);

    const { tourExceptions, openDeviationDetails, selectedDeviationReport, closeDeviationDetails, isDeviationModalOpen, isLoading, canRenderOverview } =
        useTourExceptionsOverview({ report, shouldAutoTranslate, sharedReportId });

    return (
        canRenderOverview && (
            <>
                <ReportDisplayModal
                    isOpen={isDeviationModalOpen}
                    report={selectedDeviationReport}
                    siteObject={siteObject}
                    onClose={closeDeviationDetails}
                    isReportListDetails={leftOffset > 200}
                />
                <DynamicTemplateSection
                    label={t("reportdetails.report.tourExceptions")}
                    collapsedLabel={t("reportdetails.summary.moreDetails")}
                    expandedLabel={t("reportdetails.summary.lessDetails")}
                    setLeftOffsetHandler={setLeftOffset}
                >
                    {isLoading ? (
                        <LoadingIndicator size="S" />
                    ) : (
                        <>
                            {tourExceptions.tourMultiExceptions?.map((tme) =>
                                tme.exceptionQuestions?.map(({ question, answer }, index) => (
                                    <TourMultiExceptionField
                                        label={question}
                                        value={answer}
                                        isFirst={!index}
                                        isLast={index === tme.exceptionQuestions.length - 1}
                                        disabled={!tme.report}
                                        onClick={() => tme.report && openDeviationDetails(tme.report)}
                                        key={`${question}_${index}_multi`}
                                    />
                                )),
                            )}
                            {tourExceptions.tourExceptions?.map(({ question, answer, report }, index) => (
                                <TourExceptionField label={question} value={answer} onClick={() => openDeviationDetails(report)} key={`${question}_${index}`} />
                            ))}
                        </>
                    )}
                </DynamicTemplateSection>
            </>
        )
    );
};
