import styled from "styled-components";
import { ButtonText, Palette } from "@secuis/ccp-react-components";

export const ButtonTextStyled = styled(ButtonText)`
    color: ${Palette.Orange300};

    svg {
        color: ${Palette.Orange300};
    }

    &:hover {
        color: ${Palette.Orange400};

        svg {
            color: ${Palette.Orange400};
        }
    }
`;
