import { Background, Graph, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Cell, Pie } from "recharts";
import { QaProps } from "src/models";

import { EnhancedResponsiveContainer, Widget } from "../shared/components";
import { ChartLabel } from "./ChartLabel";
import { ChartLine } from "./ChartLine";
import { CHART_LEGEND_CATEGORIES } from "./IncidentDistributionWidget.constants";
import { useIncidentDistributionWidget } from "./IncidentDistributionWidget.hooks";
import { LegendItem, StyledPieChart, ValueTileStyled } from "./IncidentDistributionWidget.styles";

type Props = QaProps;

const PIE_CHART_MARGIN = 50;

export const IncidentDistributionWidget = (props: Props) => {
    const { chartData, incidentCount, isLoading } = useIncidentDistributionWidget();
    const { t } = useTranslation();
    const chartMargin = PIE_CHART_MARGIN;
    const isEmpty = !isLoading && !chartData.length;

    return (
        <Widget
            {...props}
            data-testid="event-distribution-widget"
            title={t("insights.summary.incidentDistributionWidget.title")}
            subtitle={
                !isLoading &&
                !!chartData.length && (
                    <ValueTileStyled
                        align="left"
                        value={incidentCount.value}
                        previousValue={incidentCount.previousValue}
                        label={t("common.event", { count: incidentCount.value })}
                    />
                )
            }
            empty={isEmpty}
            loading={isLoading}
            footer={
                <Stack gap="S" flexWrap="wrap">
                    {CHART_LEGEND_CATEGORIES.map((item) => (
                        <Stack data-testid="incident-distribution-chart-legend-item" gap="XS" key={item.label} alignItems="flex-start">
                            <LegendItem backgroundColor={item.color}>{item.label}</LegendItem>
                            <Text micro>{t(`incident.category.${item.key}`)}</Text>
                        </Stack>
                    ))}
                </Stack>
            }
        >
            {/*
            NOTE Additional Stack is a temporary workaround for
            pie chart not rendered on mobile inside SummaryGrid;
            https://secsisip.atlassian.net/browse/GLOB-5652
            Can be removed after enabling grid customization for everyone
            */}
            <Stack flex={1}>
                <EnhancedResponsiveContainer>
                    <StyledPieChart margin={{ top: chartMargin, right: chartMargin, bottom: chartMargin, left: chartMargin }}>
                        <Pie
                            data={chartData}
                            nameKey="label"
                            dataKey="value"
                            isAnimationActive={false}
                            label={ChartLabel}
                            labelLine={ChartLine}
                            startAngle={90}
                            endAngle={-270}
                            minAngle={3}
                        >
                            {chartData.map((entry) => (
                                <Cell
                                    key={`pie-cell-${entry.label}`}
                                    strokeWidth={3}
                                    strokeLinejoin="round"
                                    stroke={Background.content}
                                    fill={Graph[entry.color]}
                                    onClick={entry.onItemClick}
                                />
                            ))}
                        </Pie>
                    </StyledPieChart>
                </EnhancedResponsiveContainer>
            </Stack>
        </Widget>
    );
};
