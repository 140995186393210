import { ResponsiveContainer, ResponsiveContainerProps } from "recharts";

// NOTE: Kinda workaround for incorrect resizing in recharts, when wrapped in flexbox container.
// The issue occurs typically when chart container is getting smaller.
// https://secsisip.atlassian.net/browse/GLOB-5565
export const EnhancedResponsiveContainer = ({ children, ...props }: ResponsiveContainerProps) => {
    return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
            >
                <ResponsiveContainer {...props}>{children}</ResponsiveContainer>
            </div>
        </div>
    );
};
