import { Breakpoints, ButtonText, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpansionTextPanel } from "src/components/shared/ExpansionTextPanel";

import { SEVERITY_DESCRIPTIONS } from "../../ClientSeverityLevel.constants";
import { SeverityLevel } from "../SeverityLevel/SeverityLevel";
import { StackStyled } from "./SeverityLevelInfo.styles";

export const SeverityLevelInfo = () => {
    const { t } = useTranslation();
    const isSmallScreen = useHasMaxWidth(Breakpoints.L);
    const [isExpanded, setIsExpanded] = useState(!isSmallScreen);

    useEffect(() => {
        setIsExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    const toggleContent = () => setIsExpanded((prev) => !prev);

    return (
        <StackStyled>
            {isSmallScreen && (
                <Stack justifyContent="space-between">
                    <Text small>{t("settings.clientSeverityLevel.Info.Header")}</Text>
                    <ButtonText micro onClick={toggleContent} icon={isExpanded ? "ArrowUp" : "ArrowDown"} direction="row-reverse">
                        {isExpanded ? t("common.showLess") : t("common.showMore")}
                    </ButtonText>
                </Stack>
            )}

            {isExpanded && (
                <Stack mt={isSmallScreen ? "S" : "0"} gap="S" direction="column" data-testid="settings-csl-collapsable-content">
                    {SEVERITY_DESCRIPTIONS.map(({ severityLevel, description }) => (
                        <ExpansionTextPanel
                            key={severityLevel}
                            Header={<SeverityLevel severityLevel={severityLevel} />}
                            text={t(description)}
                            visibleCollapsedTextLinesCount={2}
                        />
                    ))}
                </Stack>
            )}
        </StackStyled>
    );
};
