import { Breakpoints, Spacing } from "@secuis/ccp-react-components";

import { ColumnsPerBreakpoint, ScreenWidthVariant } from "./DraggableGrid.types";

export const GRID_ROW_SIZE = 193;

export const WIDGET_TOOLBAR_HEIGHT = Spacing.S;

// NOTE: RGL calculates breakpoints based on container width,
//       not the screen width.
export const GRID_BREAKPOINTS: ColumnsPerBreakpoint = {
    lg: parseInt(Breakpoints.S),
    md: parseInt(Breakpoints.S),
    sm: parseInt(Breakpoints.S),
    xs: parseInt(Breakpoints.XS),
    xxs: 0,
};

export const LAYOUT_BREAKPOINTS: ScreenWidthVariant[] = ["lg", "md", "sm", "xs", "xxs"];

// NOTE: Maybe we should reduce the amount of variants,
export const DEFAULT_COLUMNS_PER_BREAKPOINT: ColumnsPerBreakpoint = {
    lg: 3,
    md: 3,
    sm: 3,
    xs: 2,
    xxs: 1,
};
