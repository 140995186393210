import { Box, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

type Props = {
    count: number;
};

export const CategoriesCount = ({ count }: Props) => {
    const { t } = useTranslation();

    return (
        <Box mb="S">
            <Text small bold color="tertiary">
                {t("settings.clientSeverityLevel.Table.Count.category", { count })}
            </Text>
        </Box>
    );
};
