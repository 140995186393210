import { Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { ViewExceptionButton } from "../ViewExceptionButton";
import { WrapperStyled } from "./TourMultiExceptionField.styles";

export const TourMultiExceptionField = ({ onClick, label, value, disabled = false, isFirst, isLast }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <WrapperStyled isFirst={isFirst} isLast={isLast} direction="column" ph={["M", "S", "XS"]}>
            <Stack direction="column" mb={"S"}>
                <Stack justifyContent="space-between">
                    <Text>{label}</Text>
                    {isFirst && !isMobile && <ViewExceptionButton onClick={onClick} disabled={disabled} />}
                </Stack>
                <Text>{value || t("common.noDataFound")}</Text>
                {isLast && isMobile && (
                    <Stack justifyContent="flex-end">
                        <ViewExceptionButton onClick={onClick} disabled={disabled} />
                    </Stack>
                )}
            </Stack>
        </WrapperStyled>
    );
};
