import { Breakpoints } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useRef, useState } from "react";

import { useResizeObserver } from "./CommonHooks";

export const useElementHasMaxWidth = (breakpoint: Breakpoints) => {
    const elementRef = useRef<HTMLElement>(null);
    const [width, setWidth] = useState(elementRef?.current?.clientWidth || 0);
    const [elementWidth] = useResizeObserver(elementRef?.current);
    const updateSize = useCallback(() => {
        if (elementRef.current) {
            setWidth(elementRef.current?.clientWidth || 0);
        }
    }, [elementRef]);

    useEffect(() => {
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, [updateSize]);

    useEffect(() => {
        setWidth(elementWidth);
    }, [elementWidth]);

    return {
        elementRef,
        hasMaxWidth: width <= parseInt(breakpoint),
    };
};
