import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { useAutoTranslatedText } from "src/helpers/autoTranslations";

import { useAutoTranslationWhenNotTranslated } from "../../../helpers/autoTranslations/hooks";
import { IReportImage } from "../../../models/ReportImagesModels";
import { styles } from "../pdfStyles";
import { PdfImagesCarousel } from "./PdfImageSection";

type PropsType = {
    title: string;
    description: string;
    images?: IReportImage[];
    isRequired?: boolean;
    shouldAutoTranslate?: boolean;
};

export const PdfReportText = ({ title, description, images = [], isRequired = true, shouldAutoTranslate = true }: PropsType) => {
    const { t } = useTranslation();
    const parsedDescription = description ? description.replaceAll("\r\n", "\n").replaceAll("\r", "\n") : null;
    const { translatedText: labelText } = useAutoTranslationWhenNotTranslated(title);
    const { translatedText: translatedDescription } = useAutoTranslatedText(parsedDescription, shouldAutoTranslate);
    const pictures = images.filter((i) => i.reportFieldName?.startsWith("picture"));

    if (isRequired && !title && !description && !pictures?.length) {
        return (
            <View style={{ marginBottom: 6 }}>
                <Text style={styles.sectionTextHeader}>{t("common.noDataFound")}</Text>
            </View>
        );
    }

    return (
        <>
            {(labelText || translatedDescription) && (
                <View style={{ marginBottom: 6 }}>
                    {labelText && <Text style={styles.sectionTextHeader}>{labelText}</Text>}
                    <Text>{translatedDescription}</Text>
                </View>
            )}
            {!!pictures.length && <PdfImagesCarousel images={pictures} />}
        </>
    );
};
