import { useMemo } from "react";

import { useAutoTranslatedExceptions } from "../../../helpers/autoTranslations";
import { ExceptionQuestionData } from "../../../models/reportTemplates";
/**
 * NOTE: Using helper function from desktop equivalent to not duplicate code.
 * To not overcomplicate already substantial PR, I'm not moving it higher in the file tree.
 * All components related to report configs and details require a little refactor,
 * so we'll handle it all then.
 */
import { parseRelatedReportsToExceptions, parseSharedReportToExceptions } from "../../Reports/TourExceptionOverview/TourExceptionsOverview.helpers";

export const useTourExceptionsOverviewPdf = ({ report, sharedReportId, shouldAutoTranslate = true }) => {
    const { translationsMap } = useAutoTranslatedExceptions(report, shouldAutoTranslate);

    const tourExceptions = useMemo(() => {
        const { tourExceptions, tourMultiExceptions } = sharedReportId
            ? parseSharedReportToExceptions(report)
            : parseRelatedReportsToExceptions(report, translationsMap);

        return [
            ...tourMultiExceptions.reduce(
                (multiExceptions, exceptions) => {
                    return [...multiExceptions, ...exceptions.exceptionQuestions];
                },
                [] as Pick<ExceptionQuestionData, "question" | "answer">[],
            ),
            ...(tourExceptions ?? []),
        ];
    }, [report, translationsMap, sharedReportId]);

    return { tourExceptions };
};
