import { useMemo, useState } from "react";
import { useAutoTranslatedExceptions } from "src/helpers/autoTranslations";
import { DynamicReportType, PatrolTourType } from "src/models/ReportModel";
import { useRelatedReportsReady } from "src/store/reports/ReportsHooks";

import { parseRelatedReportsToExceptions, parseSharedReportToExceptions } from "./TourExceptionsOverview.helpers";

export const useTourExceptionsOverview = ({
    report,
    shouldAutoTranslate,
    sharedReportId,
}: {
    report: PatrolTourType;
    shouldAutoTranslate?: boolean;
    sharedReportId?: string;
}) => {
    const [selectedReport, setSelectedReport] = useState<DynamicReportType>(null);
    const { translationsMap } = useAutoTranslatedExceptions(report, shouldAutoTranslate);
    const isRelatedReportReady = useRelatedReportsReady(report);

    const openDeviationDetails = (selectedReport: DynamicReportType) => {
        setSelectedReport(selectedReport);
    };

    const closeDeviationDetails = () => {
        setSelectedReport(null);
    };

    const tourExceptions = useMemo(
        () => (sharedReportId ? parseSharedReportToExceptions(report) : parseRelatedReportsToExceptions(report, translationsMap)),
        [report, translationsMap, sharedReportId],
    );

    const isReportWithExceptions = !sharedReportId && (tourExceptions.tourMultiExceptions.length || tourExceptions.tourExceptions.length);
    const isSharedReportWithExceptions = sharedReportId && report.exceptions.length;

    return {
        tourExceptions,
        openDeviationDetails,
        closeDeviationDetails,
        isDeviationModalOpen: !!selectedReport,
        isLoading: !sharedReportId && !isRelatedReportReady,
        selectedDeviationReport: selectedReport,
        canRenderOverview: isReportWithExceptions || isSharedReportWithExceptions,
    };
};
