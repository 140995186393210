import { ButtonText, Search, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SearchStyled = styled(Search)`
    &:focus-visible {
        outline: none;
    }
`;

export const ButtonTextStyled = styled(ButtonText)`
    margin-top: ${Spacing.XXS}px;
`;

export const ChipsContainer = styled.div`
    display: contents;
`;

export const StackStyled = styled(Stack).attrs({ mt: "S" })`
    flex-wrap: wrap;
    gap: ${Spacing.S}px ${Spacing.XS}px;
`;
