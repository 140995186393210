import { Box, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { PatrolTourType } from "src/models/ReportModel";

import { CheckpointsSummary, Container, Dot, HiddenPieChartContainer, PercentageText } from "./PatrolSummary.styled";
import { GetCheckpointsSettingsData } from "./PatrolSummaryHelper";

type Props = {
    report: PatrolTourType;
};

export const PatrolSummary = ({ report }: Props) => {
    const { t } = useTranslation();
    const checkpoints = GetCheckpointsSettingsData(report);

    return (
        <>
            <Container justifyContent="space-between" padding="M">
                <div>
                    <Box mr={"S"}>
                        <Text>{report.tourName}</Text>
                    </Box>
                    <Stack direction="row">
                        <PercentageText isLow={checkpoints.percentage < 91}>{checkpoints.percentage}%</PercentageText>
                        <div>
                            <ChekpointsPieChart data={[checkpoints.missed, checkpoints.exceptions, checkpoints.scanned]} />
                        </div>
                    </Stack>
                </div>
                <CheckpointsSummary direction="column">
                    <Stack direction="row" justifyContent="space-between" pr="M" pl="XS">
                        <div>{t(checkpoints.total.name)}</div>
                        <div>{checkpoints.total.value}</div>
                    </Stack>
                    <HorizontalLine />
                    <Stack direction="row" justifyContent="space-between" pr="M" pl="XS">
                        <Stack direction="row" alignItems="center">
                            <Dot color={checkpoints.scanned.color} />
                            {t(checkpoints.scanned.name)}
                        </Stack>
                        <div>{checkpoints.scanned.value}</div>
                    </Stack>
                    <HorizontalLine />
                    {checkpoints.exceptions.value > 0 && (
                        <>
                            <Stack direction="row" justifyContent="space-between" pr="M" pl="XS">
                                <Stack direction="row" alignItems="center">
                                    <Dot color={checkpoints.exceptions.color} marginLeft={Spacing.S} />
                                    {t(checkpoints.exceptions.name)}
                                </Stack>
                                <div>{checkpoints.exceptions.value}</div>
                            </Stack>
                            <HorizontalLine />
                        </>
                    )}
                    <Stack direction="row" justifyContent="space-between" pr="M" pl="XS">
                        <Stack direction="row" alignItems="center">
                            <Dot color={checkpoints.missed.color} />
                            {t(checkpoints.missed.name)}
                        </Stack>
                        <div>{checkpoints.missed.value}</div>
                    </Stack>
                </CheckpointsSummary>
            </Container>

            <ChekpointsPieChartPdf data={[checkpoints.missed, checkpoints.exceptions, checkpoints.scanned]} />
        </>
    );
};

const ChekpointsPieChart = ({ data }) => {
    return (
        <ResponsiveContainer width={160} height={160}>
            <PieChart width={128} height={128} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                <Pie data={data} dataKey="value" isAnimationActive={false} stroke="none" startAngle={-270} rootTabIndex={-1}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

const ChekpointsPieChartPdf = ({ data }) => {
    return createPortal(
        <HiddenPieChartContainer>
            <ResponsiveContainer width={160} height={160} id="chart-pie-pdf">
                <PieChart width={128} height={128} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                    <Pie data={data} dataKey="value" isAnimationActive={false} stroke="none" startAngle={-270}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.colorPdf} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </HiddenPieChartContainer>,
        document.body,
    );
};
