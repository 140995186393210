import i18next from "i18next";

import {
    getBooleanValue,
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getFieldValueByFieldName,
    getSiteLocation,
    hasNrsField,
    isNrsReport,
} from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { EventField, IncidentFields, oldFieldCatalogIdMap } from "../../../models/reportTemplates/Incident";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const getIncidentReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const isV1Report = report.properties.some((prop) => prop.reportFieldName === IncidentFields.description);
    const isV2Report = report.properties.some(
        (prop) => prop.reportFieldName === IncidentFields.incidentDiscoveryTime || prop.reportFieldName === IncidentFields.eventDiscoveryTime,
    );
    const mobileDirection = "column";

    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),
        conditionalRender(shouldRenderDescription(report), () => ({
            component: DynamicTemplateSection,
            props: {
                label: getFieldNameByCatalogId(EventField.Description, report) ?? "reportdetails.incidentReportDescription",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        title: getFieldValueByCatalogId(EventField.Title, report, oldFieldCatalogIdMap),
                        description: getFieldValueByCatalogId(EventField.Description, report, oldFieldCatalogIdMap),
                        sharedReportId: sharedReportId,
                        imagesData: report.images,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.incidentReportSummary",
                collapsedLabel: "reportdetails.summary.moreDetails",
                expandedLabel: "reportdetails.summary.lessDetails",
            },
            children: [
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.Location, report) ?? "reportdetails.summary.location.title",
                        fieldValue: getSiteLocation(report),
                        mobileDirection,
                        shouldAutoTranslate: false,
                    },
                },
                // --- V1 report fields
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.PoliceInvolved, report) ?? "common.policeInvolved",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.PoliceInvolved, report, oldFieldCatalogIdMap)),
                        mobileDirection,
                        isRequired: isV1Report || hasNrsField(EventField.PoliceInvolved, report),
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.MedicalServicesInvolved, report) ?? "common.medicalsInvolved",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.MedicalServicesInvolved, report, oldFieldCatalogIdMap)),
                        mobileDirection,
                        isRequired: isV1Report || hasNrsField(EventField.MedicalServicesInvolved, report),
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.FireDepartmentInvolved, report) ?? "common.fireDeptInvolved",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.FireDepartmentInvolved, report, oldFieldCatalogIdMap)),
                        mobileDirection,
                        isRequired: isV1Report || hasNrsField(EventField.FireDepartmentInvolved, report),
                    },
                },
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.SupervisorNotified, report) ?? "common.supervisorNotified",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.SupervisorNotified, report, oldFieldCatalogIdMap)),
                        mobileDirection,
                        isRequired: isV1Report || hasNrsField(EventField.SupervisorNotified, report),
                    },
                },
                // --- V2 report fields
                {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.DiscoveryTimeAndDate, report) ?? "reportdetails.incident.timeOfEvent",
                        fieldValue: getFieldValueByCatalogId(EventField.DiscoveryTimeAndDate, report, oldFieldCatalogIdMap),
                        isRequired: isV2Report || hasNrsField(EventField.DiscoveryTimeAndDate, report),
                        mobileDirection,
                        shouldAutoTranslate: false,
                    },
                },
                // ---
                ...getUnknownFieldsComponentsNRS({
                    report,
                    sharingId: sharedReportId,
                    excludedCatalogIds: Object.values(EventField),
                    oldFieldFallbackMap: oldFieldCatalogIdMap,
                }),
            ],
        },
        conditionalRender(checkIfIncidentHasPersonalData(report), () => ({
            component: DynamicTemplateSection,
            props: {
                label: getFieldNameByCatalogId(EventField.PersonalData, report) ?? "reportdetails.incident.personalData",
            },
            private: true,
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByCatalogId(EventField.PersonalData, report, oldFieldCatalogIdMap),
                        subtitle: i18next.t("reportdetails.incident.personalDataGdpr"),
                        mobileDirection: "column",
                        shouldAutoTranslate: false,
                    },
                },
            ],
        })),
    ];
};

const shouldRenderDescription = (report: DynamicReportType) => {
    const isNrs = isNrsReport(report);
    const hasNrsTitle = hasNrsField(EventField.Title, report);
    const hasNrsDesc = hasNrsField(EventField.Description, report);
    const hasValue =
        !!getFieldValueByCatalogId(EventField.Description, report, oldFieldCatalogIdMap) ||
        !!getFieldValueByCatalogId(EventField.Title, report, oldFieldCatalogIdMap) ||
        !!report.images?.length;

    return hasValue || !(isNrs && !hasNrsTitle && !hasNrsDesc);
};

const checkIfIncidentHasPersonalData = (report: DynamicReportType): boolean => {
    const personalDataValue = getFieldValueByCatalogId(EventField.PersonalData, report, oldFieldCatalogIdMap);
    const hasNrsPersonalDataProp = hasNrsField(EventField.PersonalData, report);

    return !!personalDataValue || hasNrsPersonalDataProp;
};
