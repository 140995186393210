import { useRef } from "react";
import { PageTabs } from "src/components/shared/PageTabs";
import { PageNames } from "src/models/tracking/pageView";
import { useTrackPageVisit } from "src/store/tracking/TrackingHooks";

import { SETTINGS_TABS } from "./Settings.constants";
import { SettingsRefProvider } from "./Settings.provider";
import { WrapperStyled } from "./Settings.styles";
import { SettingsHeader } from "./SettingsHeader/SettingsHeader";

export const Settings = () => {
    useTrackPageVisit(PageNames.Settings);
    const settingsRef = useRef<HTMLDivElement>(null);

    return (
        <WrapperStyled ref={settingsRef}>
            <SettingsRefProvider settingsRef={settingsRef}>
                <SettingsHeader />
                <PageTabs tabs={SETTINGS_TABS} />
            </SettingsRefProvider>
        </WrapperStyled>
    );
};
