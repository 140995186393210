import { ReactNode } from "react";
import { ConfigFuncType, DynamicComponentConfigType, DynamicConfigPropsType, PatrolConfigFuncType } from "src/models/DynamicTemplate";
import { IReportImage } from "src/models/ReportImagesModels";
import { DynamicReportPropertyType, DynamicReportPropertyTypeEnum, DynamicReportType, PatrolTourType } from "src/models/ReportModel";

import {
    getFieldLabel,
    getUniqueReportProperties,
    getUnknownFieldLabel,
    getUnknownFieldValue,
} from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { ImageSummaryFieldPdf, TextSummaryFieldPdf } from "../../PdfReports/shared/PdfSummarySections";
import { ImageField } from "../SharedComponents/ImageField";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";

const renderComponentsFromConfig = (config: DynamicComponentConfigType[], props: DynamicConfigPropsType<DynamicReportType | PatrolTourType>): ReactNode[] =>
    config.map((item, index) => {
        const Component = item.component;
        const componentProps = item.props ?? null;
        const children = item.children?.length ? renderComponentsFromConfig(item.children, props) : null;

        if (item.private && props.sharedReportId) {
            return null;
        }

        // dont render if no data or component
        if (!item.component || (!componentProps && !children)) {
            return null;
        }

        if (children) {
            return (
                <Component key={index} {...componentProps}>
                    {children}
                </Component>
            );
        }
        return <Component key={index} {...componentProps} />;
    });

export const generateReportComponents = (
    props: DynamicConfigPropsType<DynamicReportType | PatrolTourType | any>,
    configFn: ConfigFuncType | PatrolConfigFuncType,
): ReactNode[] => {
    const {
        report: { template, tourSourceId },
    } = props;

    if (!template && !tourSourceId) {
        return null;
    }
    const config = configFn(props);

    if (!config.length) {
        return [];
    }

    return renderComponentsFromConfig(config, props);
};

/**
 * @deprecated Due to New Reporting Strategy we should use getUnknownFieldsComponentsNRS instead
 */
export const getUnknownFieldsComponents = (
    report: DynamicReportType,
    sharingId: string,
    fields?: string[],
    hasValue?: boolean,
): DynamicComponentConfigType[] => {
    const uniqueProperties = getUniqueReportProperties(report);
    const fieldComponents: DynamicComponentConfigType[] = uniqueProperties.map((prop) => {
        const isUnknownField = !fields.map((f) => f.toLowerCase()).includes(prop.reportFieldName.toLowerCase());
        if (!isUnknownField || (hasValue && !prop.value)) {
            return null;
        }
        if (prop.type === DynamicReportPropertyTypeEnum.image) {
            return {
                component: ImageField,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: prop.value,
                    isRequired: false,
                    sharingId,
                },
            };
        }
        if (prop.type !== DynamicReportPropertyTypeEnum.resource) {
            return {
                component: TextSummaryField,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: getUnknownFieldValue(prop.value, prop.type),
                    isRequired: false,
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            };
        }
    });
    return fieldComponents.filter((item) => item);
};

// NOTE: To be renamed after full migration
export const getUnknownFieldsComponentsNRS = (params: {
    report: DynamicReportType;
    sharingId?: string;
    excludedCatalogIds?: string[];
    oldFieldFallbackMap?: Record<string, string>;
    images?: IReportImage[];
    forPdf?: boolean;
}): DynamicComponentConfigType[] => {
    const uniqueProperties = getUniqueReportProperties(params.report);
    const excludedFieldNames = Object.keys(params.oldFieldFallbackMap ?? {}).map((name) => name.toLowerCase());

    const unknown = uniqueProperties
        // NOTE exclude fields with catalog ID that is explicitly used in the view
        .filter(({ fieldTag }) => !fieldTag || !(params.excludedCatalogIds ?? []).includes(fieldTag))
        // NOTE exclude fields with names that are explicitly used in the view
        .filter(({ reportFieldName: name, fieldTag }) => !!fieldTag || !excludedFieldNames.includes(name.toLowerCase()))
        .map((property) => prepareComponentConfigForField(property, params.sharingId, params.forPdf, params.images))
        .filter(Boolean);

    return unknown;
};

const prepareComponentConfigForField = (
    field: DynamicReportPropertyType,
    sharingId?: string,
    forPdf?: boolean,
    images?: IReportImage[],
): DynamicComponentConfigType | undefined => {
    const fieldLabel = getFieldLabel(field);
    let componentConfig: DynamicComponentConfigType | undefined;

    // NOTE: In NRS, if the field exists in data, we want to display it (so isRequired === true)
    if (field.type === DynamicReportPropertyTypeEnum.image) {
        if (forPdf) {
            // NOTE: Will require adjustments when we know exactly how does the data look like from integration services
            const image = images.find((i) => i.reportFieldName === field.reportFieldName);
            const isImageReady = image && !image.error && !image.loading;
            if (isImageReady || field.fieldTag) {
                componentConfig = {
                    component: ImageSummaryFieldPdf,
                    props: {
                        fieldName: fieldLabel,
                        fieldValue: isImageReady ? image : null,
                        isRequired: !!field.fieldTag,
                    },
                };
            }
        } else {
            componentConfig = {
                component: ImageField,
                props: {
                    fieldName: fieldLabel,
                    fieldValue: field.value,
                    sharingId,
                    isRequired: !!field.fieldTag,
                },
            };
        }
    } else if (field.type !== DynamicReportPropertyTypeEnum.resource) {
        componentConfig = {
            component: forPdf ? TextSummaryFieldPdf : TextSummaryField,
            props: {
                fieldName: fieldLabel,
                fieldValue: getUnknownFieldValue(field.value, field.type),
                mobileDirection: "column",
                isRequired: !!field.fieldTag,
            },
        };
    }

    return componentConfig;
};

export const getPdfUnknownFields = (report: DynamicReportType, fields: string[], images: IReportImage[], hasValue?: boolean): DynamicComponentConfigType[] => {
    const uniqueProperties = getUniqueReportProperties(report);
    const fieldComponents: DynamicComponentConfigType[] = uniqueProperties.map((prop) => {
        const isUnknownField = !fields.map((f) => f.toLowerCase()).includes(prop.reportFieldName.toLowerCase());
        if (!isUnknownField) {
            return null;
        }
        if (prop.type === DynamicReportPropertyTypeEnum.image) {
            const image = images.find((i) => i.reportFieldName === prop.reportFieldName);
            if (!image || image.error || image.loading) {
                return null;
            }
            return {
                component: ImageSummaryFieldPdf,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: image,
                    isRequired: false,
                },
            };
        }
        if (hasValue && !prop.value) {
            return null;
        }
        if (prop.type !== DynamicReportPropertyTypeEnum.resource) {
            return {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: getUnknownFieldLabel(prop.reportFieldName),
                    fieldValue: getUnknownFieldValue(prop.value, prop.type),
                    isRequired: false,
                    mobileDirection: "column",
                    shouldAutoTranslate: true,
                },
            };
        }
    });
    return fieldComponents.filter((item) => item);
};

export const conditionalRender = (value, renderFn) => {
    return value ? renderFn() : [];
};
