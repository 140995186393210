export const featureFlags = {
    assignmentReports: "experiment-assignment-reports",
    autotranslations: "experiment-autotranslations",
    inMemoryDemoClient: "entitlement-in-memory-demo-client",
    viewSchedule: "release-view-schedule",
    kpiSummary: "release-kpi-summary",
    kpiDatepicker: "release-kpi-datepicker",
    sitePageTabsBarchart: "release-site-page-tabs-barchart",
    personalizeKpiSummary: "release-personalize-kpi-summary",
    mobileGuardingTracking: "release-mg-tracking",
    trueDeviations: "release-true-deviations",
    clientSeverityLevel: "release-client-severity-level",
    clientSeverityLevelEditMode: "release-client-severity-level-edit-mode",
    insightsReportSearch: "release-insights-report-search",
};
