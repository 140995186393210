import { createSlice } from "@reduxjs/toolkit";
import { mapToUser } from "src/models/UserModel";

import { DEFAULT_USER_STATE } from "./constants";
import { userApi } from "./userApi";

export const userSlice = createSlice({
    name: "user",
    initialState: DEFAULT_USER_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.setUserPreference.matchFulfilled, (state, action) => {
            const { key, value } = action.meta.arg.originalArgs;
            state.info.preferences[key] = value;
        });
        builder.addMatcher(userApi.endpoints.getUserInfo.matchFulfilled, (state, action) => {
            state.invalidated = false;
            state.info = mapToUser(action.payload);
        });
        builder.addMatcher(userApi.endpoints.getUserInfo.matchRejected, (state) => {
            state.invalidated = false;
        });
    },
});
