import { useSelector } from "react-redux";
import { formatToRawDate } from "src/helpers/date";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { EVENTS_OVERVIEW_REPORTS_TYPES } from "../constants";

export const useReportsRedirectionUrl = () => {
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);

    return (siteId: string, categoryKey: string): string => {
        return `/reports?selectedRegions=${siteId}&selectedStartDate=${formatToRawDate(selectedStartDate)}&selectedEndDate=${formatToRawDate(
            selectedEndDate,
        )}&selectedCategoryThree=${categoryKey}&selectedReportTypes=${EVENTS_OVERVIEW_REPORTS_TYPES.toString()}`;
    };
};
