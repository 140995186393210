import { PropsWithChildren } from "react";
import { createContext } from "src/helpers/createContext";

const [SettingsRefContext, useSettingsRefContext] = createContext<{
    settingsRef: React.RefObject<HTMLElement>;
}>({
    defaultValue: {
        settingsRef: null,
    },
    providerName: "SettingsRefProvider",
});

type Props = PropsWithChildren & {
    settingsRef: React.RefObject<HTMLDivElement>;
};

export const SettingsRefProvider = ({ children, settingsRef }: Props) => {
    return <SettingsRefContext.Provider value={{ settingsRef }}>{children}</SettingsRefContext.Provider>;
};

export { useSettingsRefContext };
