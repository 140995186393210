import { Spacing } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";

import { Graph, GraphLegend, Widget } from "../shared/components";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { useSiteReportsWidget } from "./SiteReportsWidget.hooks";

export const SiteReportsWidget = () => {
    const { t } = useTranslation();
    const { viewMode, graphData, isSwitcherVisible, isLoading, isEmpty, setViewMode } = useSiteReportsWidget();

    return (
        <Widget
            data-testid="site-reports-widget"
            title={t("insights.summary.siteReportsWidget.title")}
            tooltip={t(`insights.summary.siteReportsWidget.tooltip.${viewMode}`)}
            headerActions={isSwitcherVisible && <Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={setViewMode} />}
            empty={isEmpty}
            loading={isLoading}
            emptyStateProps={{ subtitle: t("common.adjustTheDateAndFilters") }}
            footer={<GraphLegend title={t("insights.summary.siteReportsWidget.graphLegend.title")} data={graphData.data} />}
        >
            <Graph style={{ marginTop: Spacing.XL }} data={graphData.data} maxValue={graphData.maxValue} barSize="md" />
        </Widget>
    );
};
