import { NavigationLink, NavigationLinkModel } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { useNavbarSettingsAppGuide } from "./Navbar.hooks";
import { NavigationLinkWrapperStyled, NavigationStyled, TooltipRichStyled } from "./Navbar.styles";

export const Navbar = () => {
    const { t } = useTranslation();
    const isClientSeverityLevelEnabled = useFeatureFlag(featureFlags.clientSeverityLevel);

    const {
        settingsAppGuideTitle,
        settingsAppGuideText,
        settingsAppGuideButtonText,
        isSettingsAppGuideVisible,
        onSettingsAppGuideClose,
        onSettingsAppGuideButtonClick,
    } = useNavbarSettingsAppGuide();

    const links: NavigationLinkModel[] = [
        { to: "/insights", icon: "Insights", label: t("navbar.link.insights") },
        { to: "/reports", icon: "Report", label: t("navbar.link.reports") },
        { to: "/sites", icon: "Building", label: t("navbar.link.objects") },
    ];

    if (isClientSeverityLevelEnabled && !isSettingsAppGuideVisible) {
        links.push({ to: "/settings", icon: "Settings", label: t("navbar.link.settings") });
    }

    return (
        <NavigationStyled title={t("navigation.title")} isExpanded isExpandable links={links} id="aside-navbar-panel">
            {isSettingsAppGuideVisible
                ? ({ isTablet }) => {
                      const navigationLinkClassName = "navbar-settings-app-guide";
                      const tooltipAnchorSelect = `.${navigationLinkClassName} > a > div > div`;
                      return (
                          <>
                              <NavigationLinkWrapperStyled className={navigationLinkClassName}>
                                  <NavigationLink to="/settings" icon="Settings" label={t("navbar.link.settings")} isTablet={isTablet} />
                              </NavigationLinkWrapperStyled>
                              <TooltipRichStyled
                                  title={settingsAppGuideTitle}
                                  buttonText={settingsAppGuideButtonText}
                                  anchorSelect={tooltipAnchorSelect}
                                  place="right"
                                  defaultIsOpen
                                  openOnClick
                                  onClick={onSettingsAppGuideButtonClick}
                                  onClose={onSettingsAppGuideClose}
                              >
                                  {settingsAppGuideText}
                              </TooltipRichStyled>
                          </>
                      );
                  }
                : null}
        </NavigationStyled>
    );
};
