export enum IncidentFields {
    title = "title",
    description = "description",
    policeInvolved = "policeInvolved",
    medicalServicesInvolved = "medicalServicesInvolved",
    fireDepartmentInvolved = "fireDepartmentInvolved",
    siteSupervisorNotified = "siteSupervisorNotified",
    personalData = "enterPersonalData",
    altDescription = "pleaseDescribeTheIncident(excludePersonalData)",
    eventDescription = "pleaseDescribeTheEvent(excludePersonalData)",
    incidentDiscoveryTime = "incidentDiscoveryTimeAndDate",
    eventDiscoveryTime = "eventDiscoveryTimeAndDate",
}

export enum EventField {
    Title = "CAT_57",
    Description = "CAT_104",
    PoliceInvolved = "CAT_44",
    MedicalServicesInvolved = "CAT_77",
    FireDepartmentInvolved = "CAT_76",
    SupervisorNotified = "CAT_73",
    PersonalData = "CAT_107",
    DiscoveryTimeAndDate = "CAT_101",
    Location = "CAT_103",
}

export const oldFieldCatalogIdMap: Record<IncidentFields, EventField> = {
    [IncidentFields.title]: EventField.Title,
    [IncidentFields.policeInvolved]: EventField.PoliceInvolved,
    [IncidentFields.medicalServicesInvolved]: EventField.MedicalServicesInvolved,
    [IncidentFields.fireDepartmentInvolved]: EventField.FireDepartmentInvolved,
    [IncidentFields.siteSupervisorNotified]: EventField.SupervisorNotified,
    [IncidentFields.personalData]: EventField.PersonalData,
    [IncidentFields.description]: EventField.Description,
    [IncidentFields.altDescription]: EventField.Description,
    [IncidentFields.eventDescription]: EventField.Description,
    [IncidentFields.incidentDiscoveryTime]: EventField.DiscoveryTimeAndDate,
    [IncidentFields.eventDiscoveryTime]: EventField.DiscoveryTimeAndDate,
};
