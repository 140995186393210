import { Palette } from "@secuis/ccp-react-components";
import { PatrolTourType } from "src/models/ReportModel";

export const GetCheckpointsSettingsData = (report: PatrolTourType) => {
    const scanned = {
        name: "reportdetails.patrolTour.scanned",
        value: report.checkpointsScannedTotal ?? 0,
        color: Palette.Green300,
        colorPdf: Palette.Green500,
    };
    const total = { name: "reportdetails.patrolTour.checkpoints", value: report.checkpointsTotal ?? 0 };
    const exceptions = {
        name: "reportdetails.patrolTour.exceptionsTitle",
        value: report.exceptionReportIds?.length ?? 0,
        color: Palette.Orange300,
        colorPdf: Palette.Orange500,
    };
    const missed = { name: "reportdetails.patrolTour.missed", value: report.checkpointsMissedTotal ?? 0, color: Palette.Red300, colorPdf: Palette.Red500 };
    return {
        missed,
        exceptions,
        scanned,
        total,
        percentage: Math.round((scanned.value * 100) / total.value),
    };
};
