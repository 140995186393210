import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Widget } from "../shared/components";

export const WidgetStyled = styled(Widget)<{ withMinHeight?: boolean }>`
    ${({ withMinHeight }) =>
        withMinHeight &&
        `
        & {
            min-height: 193px;

            @media (max-width: ${Breakpoints.XS}) {
                min-height: 220px;
            }
        }`}
`;

export const GridLayoutStyled = styled.div`
    width: 100%;
    display: grid;
    gap: ${Spacing.S}px;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: auto;

    @media (min-width: ${Breakpoints.XL}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: ${Breakpoints.XS}) {
        display: flex;
        flex-direction: column;
    }
`;
