export type CategoryIncidentCountPerSite = {
    [locationId: string]: {
        [category: string]: number;
    };
};

export type DeviationsAPIData = {
    locationId: string;
    category: string;
    count: number;
}[];

export type FetchDeviationsAPIResponse = {
    data: DeviationsAPIData;
    isLoading: boolean;
};

export enum SitePageTabLabel {
    ViewSchedule = "schedule.title",
    Events = "insights.tabs.incidents",
    Tasks = "insights.tabs.tasks",
}
