import { Background, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack).attrs({
    direction: "column",
    padding: "S",
})`
    background-color: ${Background.content};
    overflow: auto;
    border-radius: ${Spacing.XXS}px;
`;
