import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { useAutoTranslationWhenNotTranslated } from "../../../helpers/autoTranslations/hooks";
import { styles } from "../pdfStyles";

export const ConditionalTextFieldPdf = ({
    fieldName,
    fieldValue,
    optionalContentName,
    optionalContentValue,
    shouldAutoTranslate = true,
    isRequired = true,
}) => {
    const { t } = useTranslation();
    const { translatedText: headerTextValue } = useAutoTranslationWhenNotTranslated(fieldValue, shouldAutoTranslate);
    const { translatedText: optionalTextValue } = useAutoTranslationWhenNotTranslated(optionalContentValue, shouldAutoTranslate);

    const { translatedText: fieldLabel } = useAutoTranslationWhenNotTranslated(fieldName);
    const { translatedText: optionalContentLabel } = useAutoTranslationWhenNotTranslated(optionalContentName);

    if (!isRequired && !fieldValue) return null;

    return (
        <View style={{ width: "100%" }} wrap={false}>
            <View style={styles.inlineFullWidth}>
                <Text style={{ ...styles.boldText, width: "80%" }}>{fieldLabel}</Text>
                <Text>{headerTextValue || t("common.noDataFound")}</Text>
            </View>
            {optionalContentName && (
                <>
                    <Text style={{ ...styles.boldText, marginTop: 8 }}>{optionalContentLabel}</Text>
                    <Text>{optionalTextValue || t("common.noDataFound")}</Text>
                </>
            )}
        </View>
    );
};
