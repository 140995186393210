import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCountIncidentsAndDeviations, useReportsRedirectionUrl } from "src/pages/Insights/shared/hooks";
import { SelectedCategories } from "src/pages/Insights/SitePage/InsightsSitePage.types";

import { BarStyled, ValueTileStyled } from "./TrendIndicatorBar.styles";

type TrendIndicatorBarProps = {
    categoryKey: string;
    siteId?: string;
    targetCategory?: string;
    selectedCategories?: SelectedCategories;
    levelChangeHandler?: (selectedCategories: SelectedCategories) => void;
};

export const TrendIndicatorBar = ({
    categoryKey,
    siteId,
    targetCategory,
    levelChangeHandler,
    selectedCategories = {} as SelectedCategories,
}: TrendIndicatorBarProps) => {
    const { isLoading, currentPeriodCount, previousPeriodCount } = useCountIncidentsAndDeviations(categoryKey, siteId);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const getRedirectionUrl = useReportsRedirectionUrl();

    const reportsUrl = getRedirectionUrl(siteId, targetCategory);

    const navigationHandler = useCallback(() => {
        if (selectedCategories?.level1 && selectedCategories?.level2) {
            navigate(reportsUrl);
        } else if (levelChangeHandler) {
            levelChangeHandler({
                level1: selectedCategories?.level1 ?? targetCategory,
                level2: selectedCategories?.level1 && targetCategory,
            });
        }
    }, [targetCategory, selectedCategories, reportsUrl, navigate, levelChangeHandler]);

    return (
        <BarStyled>
            <ValueTileStyled
                data-testid="trend-indicator-bar"
                loading={isLoading}
                value={currentPeriodCount}
                previousValue={previousPeriodCount}
                description={t("insights.summary.defaultComparison")}
                label={t("common.event", { count: currentPeriodCount })}
                direction="row"
                onClick={navigationHandler}
            />
        </BarStyled>
    );
};
