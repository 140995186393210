import { Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { DefaultItemFieldWrapper } from "src/components/Reports/DefaultItemField";

import { ViewExceptionButton } from "../ViewExceptionButton";

export const TourExceptionField = ({ onClick, label, value, disabled = false }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <DefaultItemFieldWrapper>
            <Stack direction="column">
                <Stack justifyContent="space-between">
                    {label}
                    {!isMobile && <ViewExceptionButton onClick={onClick} disabled={disabled} />}
                </Stack>
                <Text>{value || t("common.noDataFound")}</Text>
                {isMobile && (
                    <Stack justifyContent="flex-end">
                        <ViewExceptionButton onClick={onClick} disabled={disabled} />
                    </Stack>
                )}
            </Stack>
        </DefaultItemFieldWrapper>
    );
};
