import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import TrackingActions from "src/store/tracking/TrackingActions";

interface UseSearchProps {
    onChange: (value: string) => void;
    onChipsChange: (chip: string[]) => void;
}

export const useSearch = ({ onChange, onChipsChange }: UseSearchProps) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [chips, setChips] = useState<string[]>([]);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target) return;

        onChange(event.target.value);
        setSearchValue(event.target.value);
    };

    const clearSearchResults = () => {
        setHasStartedTyping(false);
        onChange("");

        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchClear));
    };

    const handleClear = () => {
        clearSearchResults();
    };

    const handleClearChips = () => {
        setChips([]);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (!hasStartedTyping) {
            setHasStartedTyping(true);
            dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchStartTyping, { Value: event.key }));
        }

        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchSubmit, { Value: searchValue }));

        if (event.key === "Enter") {
            onEnterKeyDown();
        }
    };

    const onEnterKeyDown = () => {
        setSearchValue("");
        onChange("");

        if (!chips.includes(searchValue) && searchValue) {
            setChips([...chips, searchValue]);
        }
    };

    const removeChip = (chip: string) => {
        setChips((prevChips) => prevChips.filter((c) => c !== chip));
    };

    useEffect(() => {
        onChipsChange(chips);
    }, [chips, onChipsChange]);

    return {
        chips,
        removeChip,
        handleChange,
        handleClear,
        handleClearChips,
        handleKeyDown,
    };
};
