import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";

import { UserResponse } from "./types";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        setUserPreference: builder.query<
            { key: string; value: string },
            {
                userId: string;
                key: string;
                value: any;
            }
        >({
            query: ({ userId, key, value }) => {
                const body = JSON.stringify({
                    preferences: [
                        {
                            key,
                            value,
                        },
                    ],
                });
                return {
                    url: `/v1/users/${userId}/preferences`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        getUserInfo: builder.query<
            UserResponse,
            {
                userId: string;
            }
        >({
            query: ({ userId }) => {
                return {
                    url: `/v1/users/byId/${userId}`,
                    method: "GET",
                };
            },
        }),
        getUsersByIds: builder.query<
            UserResponse[],
            {
                userIds: string[];
            }
        >({
            query: ({ userIds }) => {
                return {
                    url: `/v1/users/byIds/${userIds.join(",")}`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const { useLazySetUserPreferenceQuery, useLazyGetUserInfoQuery, useLazyGetUsersByIdsQuery } = userApi;
