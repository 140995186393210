import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReportsRedirectionUrl } from "src/pages/Insights/shared/hooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { CategoryCell, DataCell, OtherCell, OtherHeaderCell, SiteCell, SiteHeaderCell } from "../components";
import { DataItem, IncidentData, OtherColumnData } from "../IncidentMatrix.types";

// Calculate total number of incidents for a category (or sum for all "hiddenCategories" categories)
const calculateCategoryTotal = (data: DataItem[], categoryKey: string = "other"): number => {
    return data.reduce((sum, site) => {
        const incidentCounts = site[categoryKey] as IncidentData | OtherColumnData;
        const incidentCount =
            categoryKey === "other" ? (incidentCounts as OtherColumnData)?.otherIncidentCount : (incidentCounts as IncidentData)?.categoryIncidentCount;
        return sum + (incidentCount || 0);
    }, 0);
};

// Function to sum up incidents for "other" column
const addOtherItemsTotalCount = (dataItems: DataItem[], hiddenCategories: string[]) => {
    return dataItems.map((item) => {
        const otherIncidentCount = hiddenCategories.reduce((acc, category) => {
            const columnData = item[category];
            if (columnData && "categoryIncidentCount" in columnData) {
                return acc + columnData.categoryIncidentCount;
            }
            return acc;
        }, 0);

        return {
            ...item,
            other: {
                otherIncidentCount,
            },
        };
    });
};

type useColumnsProps = {
    visibleCategories: string[];
    hiddenCategories: string[];
    items: DataItem[];
    maxIncidentCount: number;
    isOtherColumnEnabled?: boolean;
};

export const useColumns = ({ visibleCategories, hiddenCategories, items, maxIncidentCount, isOtherColumnEnabled = false }: useColumnsProps) => {
    const navigate = useNavigate();

    const getRedirectionUrl = useReportsRedirectionUrl();

    const matrixCategoryLevel = useSelector(InsightsSelectors.getIncidentMatrixLevel);

    const canChangeCategory = matrixCategoryLevel !== 3;

    type DataCellProps = React.ComponentProps<typeof DataCell>;
    type SiteCellProps = React.ComponentProps<typeof SiteCell>;

    const rowsData = useMemo(() => addOtherItemsTotalCount(items, hiddenCategories), [items, hiddenCategories]);

    const columns = useMemo(() => {
        // Column definition for sites
        const sitesColumn = {
            id: "site",
            key: "site",
            HeaderCell: () => <SiteHeaderCell />,
            Cell: ({ cell, index }: { cell: SiteCellProps; index: number }) => <SiteCell index={index} id={cell.id} name={cell.name} total={cell.total} />,
        };

        // Column definition for "other" column
        const otherColumn = {
            id: "other",
            key: "other",
            HeaderCell: () => <OtherHeaderCell totalCount={calculateCategoryTotal(rowsData)} />,
            Cell: ({ cell }: { cell: React.ComponentProps<typeof OtherCell> }) => <OtherCell otherIncidentCount={cell.otherIncidentCount} />,
        };

        // Columns for visible categories
        const categoryColumns = visibleCategories.map((category) => ({
            id: category,
            key: category,
            HeaderCell: () => (
                <CategoryCell
                    categoryKey={category}
                    totalCount={calculateCategoryTotal(rowsData, category)}
                    onClick={() => canChangeCategory && navigate(`/insights/overview-events/${category}`)}
                    disabled={!canChangeCategory}
                />
            ),
            Cell: ({ cell }: { cell: DataCellProps }) => {
                const redirectUrl = getRedirectionUrl(cell.siteId, category);

                return (
                    <DataCell
                        categoryIncidentCount={cell.categoryIncidentCount}
                        maxIncidentCount={maxIncidentCount}
                        onClick={() => (canChangeCategory ? navigate(`/insights/overview-events/${category}`) : navigate(redirectUrl))}
                    />
                );
            },
        }));

        // Include "other" column if enabled
        return isOtherColumnEnabled ? [sitesColumn, ...categoryColumns, otherColumn] : [sitesColumn, ...categoryColumns];
    }, [visibleCategories, rowsData, maxIncidentCount, canChangeCategory, navigate, isOtherColumnEnabled, getRedirectionUrl]);

    return {
        columnsData: columns,
        columnsCount: visibleCategories.length + 1,
        rowsData,
    };
};
