import {
    getBooleanValue,
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getSiteLocation,
    hasNrsField,
    isNrsReport,
} from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { EventField, IncidentFields, oldFieldCatalogIdMap } from "../../../../models/reportTemplates/Incident";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";
import { IReportImage } from "../../../../models/ReportImagesModels";

export const getIncidentReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const isV1Report = report.properties.some((prop) => prop.reportFieldName === IncidentFields.description);
    const isV2Report = report.properties.some(
        (prop) => prop.reportFieldName === IncidentFields.incidentDiscoveryTime || prop.reportFieldName === IncidentFields.eventDiscoveryTime,
    );

    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(shouldRenderDescription(report, images), () => ({
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: getFieldNameByCatalogId(EventField.Description, report) ?? "reportdetails.incidentReportDescription",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        title: getFieldValueByCatalogId(EventField.Title, report, oldFieldCatalogIdMap),
                        description: getFieldValueByCatalogId(EventField.Description, report, oldFieldCatalogIdMap),
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        })),
        {
            component: SummarySectionPdf,
            props: {
                label: "reportdetails.incidentReportSummary",
            },
            children: [
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.Location, report) ?? "reportdetails.summary.location.title",
                        fieldValue: getSiteLocation(report),
                        isRequired: true,
                        shouldAutoTranslate: false,
                    },
                },
                // --- V1 report fields
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.PoliceInvolved, report) ?? "common.policeInvolved",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.PoliceInvolved, report, oldFieldCatalogIdMap)),
                        isRequired: isV1Report || hasNrsField(EventField.PoliceInvolved, report),
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.MedicalServicesInvolved, report) ?? "common.medicalsInvolved",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.MedicalServicesInvolved, report, oldFieldCatalogIdMap)),
                        isRequired: isV1Report || hasNrsField(EventField.MedicalServicesInvolved, report),
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.FireDepartmentInvolved, report) ?? "common.fireDeptInvolved",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.FireDepartmentInvolved, report, oldFieldCatalogIdMap)),
                        isRequired: isV1Report || hasNrsField(EventField.FireDepartmentInvolved, report),
                    },
                },
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.SupervisorNotified, report) ?? "common.supervisorNotified",
                        fieldValue: getBooleanValue(getFieldValueByCatalogId(EventField.SupervisorNotified, report, oldFieldCatalogIdMap)),
                        isRequired: isV1Report || hasNrsField(EventField.SupervisorNotified, report),
                    },
                },
                // --- V2 report fields
                {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(EventField.DiscoveryTimeAndDate, report) ?? "reportdetails.incident.timeOfEvent",
                        fieldValue: getFieldValueByCatalogId(EventField.DiscoveryTimeAndDate, report, oldFieldCatalogIdMap),
                        isRequired: isV2Report || hasNrsField(EventField.DiscoveryTimeAndDate, report),
                        shouldAutoTranslate: false,
                    },
                },
                //
                ...getUnknownFieldsComponentsNRS({
                    report,
                    excludedCatalogIds: Object.values(EventField),
                    oldFieldFallbackMap: oldFieldCatalogIdMap,
                    images,
                    forPdf: true,
                }),
            ],
        },
    ];
};

const shouldRenderDescription = (report: DynamicReportType, images?: IReportImage[]) => {
    const hasValue =
        !!getFieldValueByCatalogId(EventField.Description, report, oldFieldCatalogIdMap) ||
        !!getFieldValueByCatalogId(EventField.Title, report, oldFieldCatalogIdMap) ||
        !!images?.length;

    return hasValue || !(isNrsReport(report) && !hasNrsField(EventField.Description, report) && !hasNrsField(EventField.Title, report));
};
