import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { useAutoTranslationWhenNotTranslated } from "../../../helpers/autoTranslations/hooks";
import { DefaultItemRow, StackField, TextValueStyled } from "../DefaultItemField";

type Props = {
    headerName: string;
    headerValue?: string;
    optionalContentName?: string;
    optionalContentValue?: string;
    shouldAutoTranslate?: boolean;
    isRequired?: boolean;
};

export const ConditionalTextField = ({
    headerName,
    headerValue,
    optionalContentName,
    optionalContentValue,
    shouldAutoTranslate = true,
    isRequired = true,
}: Props) => {
    const { t } = useTranslation();
    const { translatedText: textValue } = useAutoTranslationWhenNotTranslated(headerValue, shouldAutoTranslate);
    const { translatedText: textOptionalValue } = useAutoTranslationWhenNotTranslated(optionalContentValue, shouldAutoTranslate);

    const { translatedText: header } = useAutoTranslationWhenNotTranslated(headerName);
    const { translatedText: optionalContentHeader } = useAutoTranslationWhenNotTranslated(optionalContentName);

    if (!headerValue && !isRequired) {
        return null;
    }

    return (
        <StackField>
            <DefaultItemRow label={header}>
                <TextValueStyled>{textValue || t("common.noDataFound")}</TextValueStyled>
            </DefaultItemRow>
            {optionalContentName && (
                <Stack mt={["S", "XS", "XXS"]}>
                    <DefaultItemRow label={optionalContentHeader} direction="column">
                        <Stack mt={["XXS", "XXS", "0"]}>
                            <TextValueStyled>{textOptionalValue || t("common.noDataFound")}</TextValueStyled>
                        </Stack>
                    </DefaultItemRow>
                </Stack>
            )}
        </StackField>
    );
};
