import { Box, Breakpoints, Palette, Spacing, ValueTile } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BarStyled = styled(Box)`
    background: ${Palette.Navy750};
    padding: 0 ${Spacing.XS}px;
    border-radius: 4px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: 0 ${Spacing.S}px;
        margin-bottom: 0;
    }
`;

export const ValueTileStyled = styled(ValueTile)`
    &:hover {
        background: ${Palette.Navy750};
    }
`;
