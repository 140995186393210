import { keyBy } from "lodash";

import { IImageData } from "../../models/ReportImagesModels";
import { DynamicReportType } from "../../models/ReportModel";
import { ExceptionQuestionData, TourMultiExceptionFields } from "../../models/reportTemplates";
import { getExceptionQuestionsAnswers, getFieldValueByFieldName } from "./dynamicTemplatePropertiesHelper";

export const extractExceptionQuestionsFromReport = (report: DynamicReportType) => {
    const serializedQuestionsData = getFieldValueByFieldName(TourMultiExceptionFields.questionsAndAnswers, report);
    const serializedQuestionsAndAnswers = getFieldValueByFieldName(TourMultiExceptionFields.exceptionQuestions, report);
    const images = report.images?.length
        ? report.images
        : report.properties.filter(({ reportFieldName }) => reportFieldName.includes("exception_")).map(({ value }) => value as unknown as IImageData);

    const questions = (serializedQuestionsData ? parseExceptionQuestions(serializedQuestionsData) : parseOldQuestionsAndAnswers(serializedQuestionsAndAnswers))
        .filter(({ question }) => !!question)
        .map((exception) => ({
            ...exception,
            question: exception.question.trim(),
        }));

    return matchQuestionsWithImages(questions, images);
};

const parseExceptionQuestions = (data: string): ExceptionQuestionData[] => (data ? JSON.parse(data) : []);

const parseOldQuestionsAndAnswers = (data: string): ExceptionQuestionData[] =>
    getExceptionQuestionsAnswers(data ?? "").map(([question, answer]) => ({ question, answer }));

const matchQuestionsWithImages = (
    questions: ExceptionQuestionData[],
    images: IImageData[],
): (ExceptionQuestionData & {
    image?: IImageData;
})[] => {
    const imagesMap = keyBy(
        images.filter(({ reportFieldName }) => !!reportFieldName?.includes("exception_")),
        (i) => i.reportFieldName.split("_")[1],
    );

    return questions.map((question) => ({
        ...question,
        image: question.questionIndex !== undefined ? imagesMap[question.questionIndex] : undefined,
    }));
};
