import { Box, ChipFilter } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { useSearch } from "./Search.hooks";
import { ButtonTextStyled, ChipsContainer, SearchStyled, StackStyled } from "./Search.styles";

interface Props {
    value: string;
    onChange: (value: string) => void;
    onChipsChange: (chip: string[]) => void;
    placeholder?: string;
}

export const Search = ({ value, onChange, onChipsChange, placeholder }: Props) => {
    const { t } = useTranslation();

    const { chips, removeChip, handleChange, handleClear, handleClearChips, handleKeyDown } = useSearch({
        onChange,
        onChipsChange,
    });

    return (
        <Box mb="S" data-testid="settings-csl-search">
            <SearchStyled value={value} onChange={handleChange} onClear={handleClear} placeholder={placeholder} onKeyDown={handleKeyDown} />

            <StackStyled>
                <ChipsContainer>
                    {chips.map((chip) => (
                        <ChipFilter
                            key={chip}
                            label={chip}
                            onRemove={() => {
                                removeChip(chip);
                            }}
                        />
                    ))}
                </ChipsContainer>

                {chips.length > 0 && (
                    <ButtonTextStyled small onClick={handleClearChips}>
                        {t("settings.clientSeverityLevel.search.clearAll")}
                    </ButtonTextStyled>
                )}
            </StackStyled>
        </Box>
    );
};

Search.displayName = "Search";
